import { environment } from "src/environments/environment";
export const ENDPOINT = {
    IDENTITY_SERVICE: `${ environment.identityServiceUrl }`,
    GENERAL_SERVICE: `${ environment.dentaFlowUrl }/api`,
    BASE_URL:`${environment.productApiUrl}/api`
};

export const CONTROLLER = {
    AUTHENTICATION: 'authentication',
    DROPDOWN: 'dropdown',
    SETUP: 'setup',
    DATA_FROM_PMS: 'dataFromPms',
    WRITE_TO_PMS: 'writeToPms',
    CLIENT: 'client',
    PRACTICE_LOCATION: 'practiceLocation',
    PRACTICE_USER: 'practiceUser',
    DATABASE: 'database',
    PATIENT: 'Patient',
    DATA_SOURCE_CONNECTION: 'dataSourceConnection',
    APPLICATION_ROLE: 'applicationRole',
    USER_ROLE: 'userRole',
    MENUS: 'menus',
    USERS: 'user',
    SOURCE_TYPE: 'sourceType',
    SOURCE_MASTER: 'sourceMaster',
    SOURCE_VERSION: 'sourceVersion',
    PAYOR_MASTER: 'payorMaster',
    PAYOR_CREDENTIALS: 'payorCredentials',
    PAYOR_TAG_MAPPING: 'payorTagMapping',
    CLEARING_HOUSE_MASTER: 'clearingHouseMaster',
    CLEARING_HOUSE_CREDENTIALS: 'clearingHouseCredentials',
    CLEARING_HOUSE_TAG_MAPPING: 'clearingHouseTagMapping',
    DATA_SOURCE: 'dataSource',
    DATA_CONTEXT: 'dataContext',
    CHILD_DATA_CONTEXT_MAPPING: 'childDataContextMapping',
    PHI: 'phi',
    SOURCE_FIELD: 'sourceField',
    DESTINATION_FIELDS: 'destinationField',
    COLUMN_MAPPING: 'columnMapping',
    DATA_EXTRACTION_FREQUENCY: 'dataExtractionFrequency',
    EXTRACTION_SCHEDULE: 'extractionSchedule',
    STATE: 'state',
    TAX_ID_MASTER: 'taxIdMaster',
    TAX_ID_USER_MAPPING: 'taxIdUserMapping',
    CONFIGURATION: 'configuration',
    EXTRACTOR: 'extractor',
    WRITE_BACK: 'writeBack',
    PRODUCT_CATEGORY: 'productCategory',
    PRODUCT_COMPONENT: 'productComponent',
    PRODUCT_SUBSCRIPTION: 'productSubscription',
    TARIFF: 'tariff',
    BAA: 'ClientBaaHistory',
    SCHEDULER: 'scheduler',
    CLAIM_DETAILS:'ClaimDetails',
    MASTER_DATA:'MasterData',
    DATA_CONFIGURATION:'DataConfiguration',
    GRID_VIEW:'GridView',
    RCM_AGENT:'RcmAgent',
    EOB_DETAILS:'EOBDeatils',
    WEB_SCRAPPER:'WebScrapper',
    REPORT: 'report',
    RCM_DASHBOARD:'RcmDashboard',
    SIGN_UP:'SignUp',
};
// WebScrapper/PrepareEobScrappingByGridViewId
export const API = {
    AUTH: {
        SIGN_IN: `${ ENDPOINT.IDENTITY_SERVICE }/Login`,
        REFRESH: `${ ENDPOINT.IDENTITY_SERVICE }/Login/Refresh`,
        SIGN_UP: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.AUTHENTICATION }/signUp`,
        SETUP_PASSWORD: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.AUTHENTICATION }/setupPassword`,
        RESET_PASSWORD: `${ ENDPOINT.IDENTITY_SERVICE }/${ CONTROLLER.SIGN_UP }/resetPassword`,
        FORGOT_PASSWORD: `${ ENDPOINT.IDENTITY_SERVICE}/${ CONTROLLER.AUTHENTICATION }/forgotPassword`,
        FORGOT_EMAIL: `${ ENDPOINT.IDENTITY_SERVICE }/${ CONTROLLER.AUTHENTICATION }/forgotEmail`,
    },
    SETUP: {
        USER: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USERS }/getCurrentUserDetails`,
        MENUS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/getActiveProductMenus`,
        LOCATIONS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/getLocations`,
        PRODUCTS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/getSubscriptions`,
        LOCATIONS_HISTORY: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/userLocationPreferenceSave`,
    },
    GRID: {
        GET: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/UserGridPreferenceGet`,
        SET: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SETUP }/UserGridPreferenceSave`,
        UPDATE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SETUP}/userGridPreferenceUpdate`,
    },
    DROPDOWN: {
        CHILD_DATA_CONTEXTS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getChildDataContexts`,
        CLEARING_HOUSES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getClearingHouses`,
        CLIENTS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getClients`,
        CLIENT_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getClientTypes`,
        CLINIC_SERVERS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getClinicServers`,
        CONNECTION_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getConnectionTypes`,
        COUNTRIES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getCountries`,
        DATA_CONTEXTS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getDataContexts`,
        JOB_FREQUENCIES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getJobFrequencies`,
        MENUS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getMenus`,
        PAYORS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPayors`,
        PAYOR_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPayorTypes`,
        PMS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPMS`,
        PMS_SERVERS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPMSServers`,
        PRACTICE_LOCATIONS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPracticeLocations`,
        PRACTICES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPractices`,
        PRODUCT_COMPONENTS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getProductComponents`,
        PRODUCTS_CATEGORIES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getProductsCategories`,
        PRODUCT_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getProductTypes`,
        SCHEDULE_POST_SUCCESS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getSchedulePostSuccess`,
        SOURCES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getSources`,
        SOURCE_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getSourceTypes`,
        STATES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getStates`,
        STATUS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getStatus`,
        TAX_IDS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getTaxIds`,
        TRANSACTION_TYPES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getTransactionTypes`,
        USER_ROLES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getUserRoles`,
        WEEKLY_OFFS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getWeeklyOffs`,
        APPLICATION_ROLES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/geApplicationRoles`,
        REPORT_CATEGORIES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getReportCategories`,
        REPORT_SQL_OPERATORS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getReportOperators`,
        REPORT_AGGREGATION_FUNCTIONS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getReportAggregationFunctions`,
    },
    DATA_FROM_PMS: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_FROM_PMS }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_FROM_PMS }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_FROM_PMS }/delete`,
    },
    WRITE_TO_PMS: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.WRITE_TO_PMS }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.WRITE_TO_PMS }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.WRITE_TO_PMS }/delete`,
    },
    CLIENT: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLIENT }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLIENT }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLIENT }/delete`,
    },
    PRACTICE_LOCATION: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRACTICE_LOCATION }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRACTICE_LOCATION }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRACTICE_LOCATION }/delete`,
    },
    PRACTICE_USER: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRACTICE_USER }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRACTICE_USER }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRACTICE_USER }/delete`,
    },
    DATABASE: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATABASE }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATABASE }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATABASE }/delete`,
    },
    DATA_SOURCE_CONNECTION: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_SOURCE_CONNECTION }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_SOURCE_CONNECTION }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_SOURCE_CONNECTION }/delete`,
    },
    APPLICATION_ROLE: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.APPLICATION_ROLE }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.APPLICATION_ROLE }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.APPLICATION_ROLE }/delete`,
    },
    USER_ROLE: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USER_ROLE }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USER_ROLE }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USER_ROLE }/delete`,
    },
    MENUS: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.MENUS }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.MENUS }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.MENUS }/delete`,
    },
    USERS: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USERS }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USERS }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.USERS }/delete`,
    },
    SOURCE_TYPE: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_TYPE }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_TYPE }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_TYPE }/delete`,
    },
    SOURCE_MASTER: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_MASTER }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_MASTER }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_MASTER }/delete`,
    },
    SOURCE_VERSION: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_VERSION }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_VERSION }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_VERSION }/delete`,
    },
    PAYOR_MASTER: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PAYOR_MASTER }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PAYOR_MASTER }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PAYOR_MASTER }/delete`,
    },
    PAYOR_CREDENTIALS: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PAYOR_CREDENTIALS }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PAYOR_CREDENTIALS }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PAYOR_CREDENTIALS }/delete`,
    },
    PAYOR_TAG_MAPPING: {
        MAPPED_PAYORS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PAYOR_TAG_MAPPING }/getAllMappedPayors`,        
        PMS_PAYORS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PAYOR_TAG_MAPPING }/getAllPMSPayors`, 
        PAYORS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PAYOR_TAG_MAPPING }/getAllPayors`,        
    },
    CLEARING_HOUSE_MASTER: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLEARING_HOUSE_MASTER }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLEARING_HOUSE_MASTER }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLEARING_HOUSE_MASTER }/delete`,
    },
    CLEARING_HOUSE_CREDENTIALS: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLEARING_HOUSE_CREDENTIALS }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLEARING_HOUSE_CREDENTIALS }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLEARING_HOUSE_CREDENTIALS }/delete`,
    },
    CLEARING_HOUSE_MAPPING: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLEARING_HOUSE_TAG_MAPPING }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLEARING_HOUSE_TAG_MAPPING }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CLEARING_HOUSE_TAG_MAPPING }/delete`,
    },
    DATA_SOURCE: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_SOURCE }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_SOURCE }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_SOURCE }/delete`,
    },
    DATA_CONTEXT: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_CONTEXT }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_CONTEXT }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_CONTEXT }/delete`,
    },
    CHILD_DATA_CONTEXT_MAPPING: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CHILD_DATA_CONTEXT_MAPPING }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CHILD_DATA_CONTEXT_MAPPING }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CHILD_DATA_CONTEXT_MAPPING }/delete`,
    },
    PHI: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PHI }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PHI }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PHI }/delete`,
    },
    SOURCE_FIELD: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_FIELD }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_FIELD }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SOURCE_FIELD }/delete`,
    },
    DESTINATION_FIELDS: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DESTINATION_FIELDS }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DESTINATION_FIELDS }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DESTINATION_FIELDS }/delete`,
    },
    COLUMN_MAPPING: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.COLUMN_MAPPING }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.COLUMN_MAPPING }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.COLUMN_MAPPING }/delete`,
    },
    DATA_EXTRACTION_FREQUENCY: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_EXTRACTION_FREQUENCY }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_EXTRACTION_FREQUENCY }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DATA_EXTRACTION_FREQUENCY }/delete`,
    },
    EXTRACTION_SCHEDULE: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.EXTRACTION_SCHEDULE }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.EXTRACTION_SCHEDULE }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.EXTRACTION_SCHEDULE }/delete`,
    },
    STATE: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.STATE }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.STATE }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.STATE }/delete`,
    },
    TAX_ID_MASTER: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_MASTER }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_MASTER }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_MASTER }/delete`,
    },
    TAX_ID_USER_MAPPING: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_USER_MAPPING }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_USER_MAPPING }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TAX_ID_USER_MAPPING }/delete`,
    },
    CONFIGURATION: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CONFIGURATION }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CONFIGURATION }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.CONFIGURATION }/delete`,
    },
    EXTRACTOR: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.EXTRACTOR }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.EXTRACTOR }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.EXTRACTOR }/delete`,
    },
    WRITE_BACK: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.WRITE_BACK }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.WRITE_BACK }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.WRITE_BACK }/delete`,
    },
    PRODUCT_CATEGORY: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_CATEGORY }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_CATEGORY }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_CATEGORY }/delete`,
    },
    PRODUCT_COMPONENT: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_COMPONENT }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_COMPONENT }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_COMPONENT }/delete`,
    },
    PRODUCT_SUBSCRIPTION: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_SUBSCRIPTION }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_SUBSCRIPTION }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.PRODUCT_SUBSCRIPTION }/delete`,
    },
    TARIFF: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TARIFF }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TARIFF }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.TARIFF }/delete`,
    },
    SCHEDULER: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SCHEDULER }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SCHEDULER }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.SCHEDULER }/delete`,
    },
    BAA: {
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.BAA }/getAll`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.BAA }/save`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.BAA }/delete`,
    },
    CLAIM_DETAILS:{
        GET_ALL_PMS: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.CLAIM_DETAILS }/GetPmsList`,
        GET_PATIENT_DETAILS: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.CLAIM_DETAILS }/GetPatientDetails`,
        GET_PATIENT_DETAILS_BY_ID: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.CLAIM_DETAILS }/GetClaimDetailsByGridViewId`,
        GET_NOTES: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.CLAIM_DETAILS }/GetNotes`,
        GET_NOTES_HISTORY: `${ ENDPOINT.BASE_URL}/${ CONTROLLER.CLAIM_DETAILS }/GetNoteHistory`,
        GET_NOTES_DETAILS: `${ ENDPOINT.BASE_URL}/${ CONTROLLER.CLAIM_DETAILS }/GetNotesDetail`,
        GET_CH_STATUS_BY_ID: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.CLAIM_DETAILS }/GetCHStatusById`,
        GET_CH_STATUS: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.CLAIM_DETAILS }/GetCHStatus`,
        GET_LOCATION: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.CLAIM_DETAILS }/GetLocations`,
        CLAIM_NOTES_UPDATE: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.CLAIM_DETAILS }/ClaimNotesUpdate`,
        UPDATE_NOTES_DETAILS: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.CLAIM_DETAILS }/UpdateNotesDetail`,
        ADVANCE_CLAIM_SEARCH: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.CLAIM_DETAILS }/AdvancedClaimSearch`,
    },
    
    MASTER_DATA:{
        GET_ALL_STATUS: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/GetClaimStatusList`,
        UPDATE: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/UpdateClaimStatus`,
        DELETE_CATEGORY: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/DeleteClaimStatusById`,
        GET_FOLLOW_UP_LIST: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/GetCustomClaimFollowUpList`,
        ADD_CLAIM_FOLLOW_UP: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/AddClaimFollowUp`,
        UPDATE_CLAIM_FOLLOW_UP: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/UpdateClaimFollowUp`,
        DELETE_CLAIM_FOLLOW_UP: `${ ENDPOINT.BASE_URL}/${ CONTROLLER.MASTER_DATA}/DeleteClaimFollowUp`,
        GET_CLAIM_STATUS_TAB: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/GetClaimStatusTabConfigurations`,
        SET_CLAIM_STATUS_TAB: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/SetClaimStatusTabConfigurations`,
        DELETE_CLAIM_STATUS_TAB: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/DeleteClaimStatusTabConfigurations`,
        SORT_CLAIM_STATUS_TAB: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/SortClaimStatusTabConfigurations`,
        GET_FILTER_DROPDOWN: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/GetFilteroptions`,
        GET_CLAIM_FOLLOW_UP_LIST_BASED_ON_PMS: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/GetClaimFollowUpListBasedOnPms`,
        GET_CLAIM_STATUS_LIST_BASED_ON_PMS: `${ ENDPOINT.BASE_URL }/${ CONTROLLER.MASTER_DATA }/GetClaimStatusListBasedOnPms`,
    },
    DATA_CONFIGURATION :{
        GET:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.DATA_CONFIGURATION }/GetAllConfiguration`,
        UPDATE:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.DATA_CONFIGURATION }/UpdateConfiguration`,
        GET_USER_LIST:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.DATA_CONFIGURATION }/GetUserAccessConfiguration`,
        UPDATE_USER_CONFIGURATION:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.DATA_CONFIGURATION }/UpdateUserAccessConfiguration`,
    },
    RCM_GRID :{
        GET_GRID:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.GRID_VIEW }/GetLandingGridViewV2`,
        GET_ELIGIBILITY_DATA:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.GRID_VIEW }/GetEligibilityData`,
        GET_CLAIM_HISTORY:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.GRID_VIEW }/GetClaimHistory/`,
        GET_FILTER_VALUE:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.GRID_VIEW }/GetDdlFilterColumnDataNew`,
        GET_ALL_GRID:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.GRID_VIEW }/GetAllClaimsByDAStatus`,
        BULK_ASSIGN:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.GRID_VIEW }/BulkAssignToAgent`,
        // GET_All_GRID:`${End}`
    },
    EOB_DETAILS:{
        GET_EOB_DETAILS:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.EOB_DETAILS }/GetEobDetails`,
        DOWNLOAD_FILE:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.EOB_DETAILS }/DownloadFile`,
        SCREENSHOT:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.EOB_DETAILS }/DownloadErrorScreenshotByScreenshotUrl`,
        GET_EOB_DATA:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.EOB_DETAILS }/GetEobData`,
    },
    AGENT:{
        GET_AGENT:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.RCM_AGENT }/GetRcmAgentList`,
        ASSIGN:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.RCM_AGENT }/AssignRCMAgent`,
    },
    SCRAPPER:{
        REVERIFY:`${ ENDPOINT.BASE_URL }/${ CONTROLLER.WEB_SCRAPPER }/PrepareEobScrappingByGridViewId`
    },
    REPORT: {
        REPORT_SELECT_FIELDS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/getReportFields`,
        REPORT_GROUPBY_FIELDS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/getReportGroupByFields`,
        REPORT_AGGREGATION_FUNCTIONS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/getReportAggregationFunctions`,
        REPORT_ORDERBY_FIELDS: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/getReportOrderByFields`,
        REPORT_VALUE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/getReportValues`,
        SAVE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/save`,
        GET_ALL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/getAll`,
        DELETE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/delete`,
        REPORT_INVOKE: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/invokeReport`,
        REPORT_CHECK_NAME: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/checkReportNameExists`,
        REPORT_DATASOURCES: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/getReportDataSources`,
        REPORT_EXCEL: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/excel`,
        REPORT_BY_REPORT_ID: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.REPORT }/getReportByReportId`,
    },
    ON_DEMAND: {
        VERIFY_PATIENTS: `${ENDPOINT.BASE_URL}/${ CONTROLLER.PATIENT }/InsertPatient`,
        SEARCH: `${ENDPOINT.BASE_URL}/${ CONTROLLER.PATIENT }/GetPatients`,
        PROVIDER:`${ENDPOINT.BASE_URL}/${CONTROLLER.PATIENT}/GetProviders`,
        CLAIM_TYPE:`${ENDPOINT.BASE_URL}/${CONTROLLER.PATIENT}/GetClaimType`,
        CLINIC: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getClinicServers`,
    },
    RCM_DASHBOARD:{
        GET_CLAIM_AGE_WISE_CLAIM_COUNTS:`${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.RCM_DASHBOARD }/GetClaimAgeWiseClaimCounts`,
        GET_CLAIM_TYPE_WISE_CLAIM_COUNTS:`${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.RCM_DASHBOARD }/GetClaimTypeWiseClaimCounts`,
        GET_DA_SATAUS_WISE_CLAIM_COUNTS:`${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.RCM_DASHBOARD }/GetDAStatusWiseClaimCounts`,
        GET_PMS_STATUS_WISE_CLAIM_COUNTS:`${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.RCM_DASHBOARD }/GetPmsStatusWiseClaimCounts`,
        GET_CLAIM_TYPE_WISE_CLAIM_AMOUNT_AND_COUNT:`${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.RCM_DASHBOARD }/GetClaimTypeWiseClaimAmountAndCounts`,
        GET_CLAIM_DENIED_WISE_PAID_IN_PROGRESS_COUNT:`${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.RCM_DASHBOARD }/GetClaimsDeniedPaidInProcessCounts`,
    }
    // https://sd-rcm-dev.azurewebsites.net/api/GridView/GetClaimHistory/
    // EOBDeatils/DownloadErrorScreenshotByScreenshotUrl
}